var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "b bg-Color tableBox padd15" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: {
                  label: "已上架商品(" + (_vm.badge.onlineQty || 0) + ")",
                  name: "OnTheShelf",
                },
              }),
              _c("el-tab-pane", {
                attrs: {
                  label: "未上架商品(" + (_vm.badge.offLineQty || 0) + ")",
                  name: "OffTheShelf",
                },
              }),
              _c("el-tab-pane", {
                attrs: {
                  label: "商城商品(" + (_vm.badge.unSyncQty || 0) + ")",
                  name: "all",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "marB15 x-bc" }, [
            _c(
              "div",
              { staticClass: "x-f" },
              [
                _vm.activeName == "OffTheShelf"
                  ? _c(
                      "el-button",
                      {
                        staticStyle: {
                          background: "#00c21a",
                          "border-color": "#00c21a",
                          color: "#fff",
                        },
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-sort-up",
                          disabled: _vm.selectData.length < 1,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.putawayBtn(0)
                          },
                        },
                      },
                      [_vm._v("上架 ")]
                    )
                  : _vm._e(),
                _vm.activeName == "OnTheShelf"
                  ? _c(
                      "el-button",
                      {
                        staticStyle: {
                          background: "#fc3c22",
                          "border-color": "#fc3c22",
                          color: "#fff",
                        },
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-sort-down",
                          disabled: _vm.selectData.length < 1,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.putawayBtn(1)
                          },
                        },
                      },
                      [_vm._v("下架 ")]
                    )
                  : _vm._e(),
                _vm.activeName != "all"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-delete",
                          disabled: _vm.selectData.length < 1,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.updateAll()
                          },
                        },
                      },
                      [_vm._v("批量修改 ")]
                    )
                  : _vm._e(),
                _vm.activeName != "all"
                  ? _c("el-input", {
                      staticClass: "inputWidth marR10 marL10",
                      attrs: {
                        size: "mini",
                        placeholder: "商城商品编号、商城商品名称、商城商品品类",
                      },
                      model: {
                        value: _vm.treeQueryParams.queryCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.treeQueryParams, "queryCode", $$v)
                        },
                        expression: "treeQueryParams.queryCode",
                      },
                    })
                  : _vm._e(),
                _vm.activeName != "all"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-search",
                        },
                        on: { click: _vm.searchOkBtn },
                      },
                      [_vm._v("搜索 ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "x-start Box" }, [
            _vm.activeName == "all"
              ? _c(
                  "div",
                  { staticClass: "treeBox" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "x-f marB15",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { placeholder: "请输入类别名称" },
                          model: {
                            value: _vm.treeQueryParams.queryCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.treeQueryParams, "queryCode", $$v)
                            },
                            expression: "treeQueryParams.queryCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("el-tree", {
                      ref: "tree",
                      attrs: {
                        data: _vm.categoryTreeOptions,
                        "node-key": "id",
                        "highlight-current": "",
                        "filter-node-method": _vm.filterNode,
                        "default-expand-all": _vm.isExpand,
                      },
                      on: { "node-click": _vm.handleNodeClick },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "y-start",
                class: {
                  marL15: _vm.activeName == "all",
                  "width100-15": _vm.activeName == "all",
                  width100: _vm.activeName != "all",
                },
              },
              [
                _vm.activeName == "all"
                  ? _c(
                      "div",
                      {
                        staticClass: "x-bc marB10",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "x-f" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  icon: "el-icon-folder-add",
                                  disabled: _vm.selectData.length < 1,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.save()
                                  },
                                },
                              },
                              [_vm._v("保存 ")]
                            ),
                            _c("el-input", {
                              staticClass: "inputWidth marL10",
                              attrs: {
                                size: "mini",
                                placeholder:
                                  "商城商品编号、商城商品名称、商城商品品类",
                              },
                              model: {
                                value: _vm.queryCode,
                                callback: function ($$v) {
                                  _vm.queryCode = $$v
                                },
                                expression: "queryCode",
                              },
                            }),
                            _c(
                              "el-button",
                              {
                                staticClass: "marL10",
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.searchOkBtn },
                              },
                              [_vm._v("搜索 ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "width100" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "goodsTable",
                        attrs: { data: _vm.goodsList, border: "", height: 500 },
                        on: { "selection-change": _vm.selectDataFn },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            align: "center",
                            width: "55",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            type: "index",
                            align: "center",
                            label: "序号",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "goodsNo",
                            align: "center",
                            label: "商品编码",
                            width: "220",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.activeName != "all"
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.editGood(
                                                scope.row.goodsId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.goodsNo) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(" " + _vm._s(scope.row.goodsNo)),
                                      ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "图片",
                            width: "100",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "50px",
                                      height: "50px",
                                    },
                                    attrs: { src: scope.row.goodsUrl },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "goodsName",
                            align: "center",
                            label: "商品名称",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "categoryName",
                            align: "center",
                            label: "商城分类",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "goodsSpec",
                            align: "center",
                            label: "商品规格",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "unitName",
                            align: "center",
                            label: "商品单位",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "status",
                            align: "center",
                            label: "上架状态",
                            formatter: function (row) {
                              return Number(row.status) === 1
                                ? "未上架"
                                : "已上架"
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "pagination-footer x-bc",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c("div", { staticClass: "x-f" }, [
                          _c("div", [
                            _vm._v(" 已选 "),
                            _c("span", { staticStyle: { color: "#1c75f4" } }, [
                              _vm._v(_vm._s(_vm.selectDataLength)),
                            ]),
                            _vm._v(" 条 "),
                          ]),
                          _c("div", { staticClass: "marL10 cursorP" }, [
                            _c(
                              "span",
                              {
                                staticStyle: { color: "#1c75f4" },
                                on: { click: _vm.selectAll },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.isSelectAll ? "取消选择" : "选择全部"
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("pagination", {
                          attrs: {
                            total: _vm.total,
                            pageSizeList: [15, 30, 50, 100],
                            page: _vm.pageNum,
                            limit: _vm.pageSize,
                          },
                          on: {
                            "update:page": function ($event) {
                              _vm.pageNum = $event
                            },
                            "update:limit": function ($event) {
                              _vm.pageSize = $event
                            },
                            pagination: _vm.nextPage,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          attrs: {
            title: _vm.isChangeAll ? "批量修改上架商品信息" : "选择商城分类",
            visible: _vm.dialogVisible,
            width: "30%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.isChangeAll
            ? _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商城分类", prop: "categoryId" } },
                    [
                      _c("Treeselect", {
                        staticStyle: { "z-index": "666" },
                        attrs: {
                          options: _vm.mallCategoryTreeOptions2,
                          "show-count": true,
                          placeholder: "请选择分类",
                          normalizer: _vm.normalizer1,
                          open: _vm.getMallCategory,
                        },
                        on: { select: _vm.select },
                        model: {
                          value: _vm.form.categoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "categoryId", $$v)
                          },
                          expression: "form.categoryId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商城分类", prop: "categoryId" } },
                    [
                      _c("Treeselect", {
                        staticStyle: { "z-index": "666" },
                        attrs: {
                          options: _vm.mallCategoryTreeOptions,
                          "show-count": true,
                          placeholder: "请选择分类",
                          normalizer: _vm.normalizer1,
                          open: _vm.getMallCategory,
                        },
                        on: { select: _vm.select },
                        model: {
                          value: _vm.form.categoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "categoryId", $$v)
                          },
                          expression: "form.categoryId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.save(false)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }