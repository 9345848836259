<template>
  <div class="quill-editor">
    <!-- 图片上传组件辅助，组件内添加v-show=“false”属性，把该组件隐藏起来。-->
    <el-upload
      class="avatar-uploader"
      :action="uploadUrl"
      :show-file-list="false"
      :on-success="uploadSuccess"
      :before-upload="beforeUpload"
    >
    </el-upload>
    <!--富文本编辑器组件-->
    <quill-editor
      v-model="content"
      :content="content"
      :options="editorOption"
      ref="QuillEditor"
    >
    </quill-editor>
    <!--        <div v-html="content"/>-->
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
  ["blockquote", "code-block"], //引用，代码块
  [{ header: 1 }, { header: 2 }], // 几级标题
  [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
  [{ script: "sub" }, { script: "super" }], // 下角标，上角标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进
  [{ direction: "rtl" }], // 文字输入方向
  [{ size: ["small", false, "large", "huge"] }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ color: [] }, { background: [] }], // 颜色选择
  [{ font: ["SimSun", "SimHei", "Microsoft-YaHei", "KaiTi", "FangSong", "Arial"] }], // 字体
  [{ align: [] }], // 居中
  ["clean"], // 清除样式,
  ["link", "image"], // 上传图片、上传视频
];
export default {
  name: "quullEditor",
  components: {
    quillEditor,
  },
  props: {
    content: {
      type: String,
    },
  },
  data() {
    return {
      name: "register-modules-example",
      editorOption: {
        placeholder: "请在这里输入",
        theme: "snow", //主题 snow/bubble
        modules: {
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false,
          },
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: (value) => {
                if (value) {
                  // 调用element的图片上传组件
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/open/oss/file/upload", // 服务器上传地址
    };
  },
  methods: {
    // 值发生变化
    onEditorChange(editor) {
      this.content = editor.html;
      console.log(editor);
    },
    beforeUpload(file) {
      console.log("beforeUpload:", file);
    },
    uploadSuccess(res) {
      console.log("res:", res);
      // 获取富文本组件实例
      let quill = this.$refs.QuillEditor.quill;
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, "image", res.data.accessUrl);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        // 提示信息，需引入Message
        this.$message.error("图片插入失败！");
      }
    },
  },
};
</script>

<style scoped></style>
