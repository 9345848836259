<template>
  <div class="x-start Box">
    <div class="treeBox y-start">
      <div class="x-f marB15" style="width: 100%">
        <el-input
          style="width: calc(100% - 35px)"
          v-model="treeSearch"
          placeholder="请输入类别名称"
        ></el-input>
        <i
          v-if="isExpand"
          class="treeIcon x-c el-icon-arrow-down"
          @click="openAllBtn"
        ></i>
        <i
          v-if="!isExpand"
          class="treeIcon x-c el-icon-arrow-up"
          @click="openAllBtn"
        ></i>
      </div>
      <el-tree
        ref="tree"
        :data="treeData"
        show-checkbox
        node-key="id"
        :default-expand-all="isExpand"
        :props="defaultProps"
      >
      </el-tree>
    </div>
    <div class="y-start marL15" style="width: 100%">
      <!--            <el-button class="marB15" type="primary" icon="el-icon-plus" @click="add">新增</el-button>-->
      <div style="width: 100%">
        <el-table
          :data="tableData"
          border
          height="500px"
          width="100%"
          @current-change="historySelectionChange"
        >
          <el-table-column type="selection" align="center" width="55" />
          <el-table-column
            prop="address"
            label="排序"
            align="center"
            width="100"
          />
          <el-table-column
            prop="date"
            label="商城分类名称"
            align="center"
            width="180"
          />
          <el-table-column
            prop="name"
            label="商城分类图片"
            align="center"
            width="180"
          />
          <!--                    <el-table-column prop="name" label="排序" align="center" width="180">-->
          <!--                        <template slot-scope="scope">-->
          <!--                            <el-button type="text">上移</el-button>-->
          <!--                            <el-button type="text">下移</el-button>-->
          <!--                        </template>-->
          <!--                    </el-table-column>-->
          <el-table-column prop="name" label="操作">
            <template slot-scope="scope">
              <el-button type="text">新增子类</el-button>
              <el-button type="text">编辑</el-button>
              <el-button type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-footer x-bc">
          <div>
            已选
            <span style="color: #1c75f4">{{ selectL }}</span>
            条
          </div>
          <pagination
            :total="queryParams.total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
          />
        </div>
      </div>
    </div>
    <el-dialog
      title="新增/修改商城分类"
      :visible.sync="dialogVisible"
      append-to-body
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <el-form model="form" inline>
          <el-form-item label="分类名称">
            <el-input v-model="form.typeName"></el-input>
          </el-form-item>
          <el-form-item label="上级分类">
            <el-select v-model="form.typeName" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addOk">保 存</el-button>
        <el-button type="danger" @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'treeAndTable',
  data () {
    return {
      treeData: [],
      isExpand: false,
      treeSearch: '',

      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tableData: [], // 列表数据
      queryParams: {
        pageSize: 15,
        pageNum: 1,
        total: 0
      },
      selectL: 0, // 选择的数据

      // 弹框
      dialogVisible: false,
      form: {
        typeName: ''
      },
      options: []
    }
  },
  methods: {
    getTree () {},
    getList () {},
    openAllBtn () {
      this.isExpand = !this.isExpand
      console.log('点击=-', this.isExpand)
      this.buildData()
    },
    //遍历树的所有子节点，展开的时候给子节点展开状态赋值true，折叠时候赋值false
    buildData () {
      for (let i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
        this.$refs.tree.store._getAllNodes()[i].expanded = this.isExpand
      }
    },
    // 接收列表选择的数据
    selectDataFn (data) {
      this.selectData = data
    },
    // 添加
    add () {
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    },
    addOk () {},
    // 列表选择
    historySelectionChange (val) {
      this.selectL = val.length
    }
  }
}
</script>

<style lang="scss" scoped>
.Box {
  .treeBox {
    border-right: 1px solid #cccccc;
    width: 260px;
    min-width: 260px;
    height: 100%;

    .treeIcon {
      width: 35px;
      height: 35px;
      font-size: 26px;
      cursor: pointer;
    }
  }

  .tableBox {
    width: calc(100% - 275px);
    height: 500px;
  }
}
</style>
