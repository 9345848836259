var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          ref: "select",
          staticStyle: { width: "100%" },
          attrs: {
            title: _vm.multiple ? _vm.optionData.name : "",
            value: _vm.value,
            placeholder: "请选择",
            clearable: "",
            disabled: _vm.disabled,
            filterable: _vm.filterable,
            "filter-method": _vm.filterMethod,
          },
          on: { clear: _vm.clear, "visible-change": _vm.visibleChange },
        },
        [
          _c(
            "el-option",
            {
              ref: "option",
              staticClass: "tree-select__option",
              attrs: { value: _vm.optionData.id, label: _vm.optionData.name },
            },
            [
              _c("el-tree", {
                ref: "tree",
                staticClass: "tree-select__tree",
                class:
                  "tree-select__tree--" + (_vm.multiple ? "checked" : "radio"),
                attrs: {
                  "node-key": _vm.nodeKey,
                  data: _vm.data,
                  props: _vm.props,
                  "default-expanded-keys": [_vm.value],
                  "show-checkbox": _vm.multiple,
                  "highlight-current": !_vm.multiple,
                  "expand-on-click-node": _vm.multiple,
                  "filter-node-method": _vm.filterNode,
                },
                on: {
                  "node-click": _vm.handleNodeClick,
                  "check-change": _vm.handleCheckChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }