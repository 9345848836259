var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.table, function (item, index) {
      return _c(
        "div",
        { key: index },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: item.tableData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", align: "center", width: "55" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("i", {
                            staticClass: "el-icon-remove operateDel",
                            attrs: { disabled: "" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteBtn("table", index)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "date",
                  align: "center",
                  label: "规格名称",
                  width: "180",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-input",
                            {
                              on: { blur: _vm.watchScroll },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.keyup(
                                    "goodsNo",
                                    $event,
                                    scope.$index
                                  )
                                },
                              },
                              model: {
                                value: scope.row.name,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "name", $$v)
                                },
                                expression: "scope.row.name",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-more",
                                attrs: { slot: "suffix" },
                                on: { click: _vm.selectGoods },
                                slot: "suffix",
                              }),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  align: "center",
                  label: "规格条码",
                  width: "180",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            model: {
                              value: scope.row.name,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "name", $$v)
                              },
                              expression: "scope.row.name",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("el-table-column", {
                attrs: { prop: "address", align: "center", label: "单位" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            model: {
                              value: scope.row.name,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "name", $$v)
                              },
                              expression: "scope.row.name",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("el-table-column", {
                attrs: { prop: "address", align: "center", label: "价格" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-input",
                            {
                              model: {
                                value: scope.row.name,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "name", $$v)
                                },
                                expression: "scope.row.name",
                              },
                            },
                            [
                              _c("template", { slot: "prepend" }, [
                                _vm._v("￥"),
                              ]),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("el-table-column", {
                attrs: { prop: "address", align: "center", label: "划线价" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-input",
                            {
                              model: {
                                value: scope.row.name,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "name", $$v)
                                },
                                expression: "scope.row.name",
                              },
                            },
                            [
                              _c("template", { slot: "prepend" }, [
                                _vm._v("￥"),
                              ]),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "库存" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-input",
                            {
                              model: {
                                value: scope.row.name,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "name", $$v)
                                },
                                expression: "scope.row.name",
                              },
                            },
                            [
                              _c("template", { slot: "prepend" }, [
                                _vm._v("￥"),
                              ]),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "specificationsBox marT10" },
            [
              _c(
                "el-collapse",
                {
                  on: { change: _vm.handleChange },
                  model: {
                    value: item.activeNames,
                    callback: function ($$v) {
                      _vm.$set(item, "activeNames", $$v)
                    },
                    expression: "item.activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "规格属性", name: "1" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品属性" } },
                            [
                              _vm._l(item.attribute, function (att, inx) {
                                return _c("div", { staticClass: "marB10" }, [
                                  _c(
                                    "div",
                                    { staticClass: "x-start" },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "inputW200",
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择商品品牌",
                                          },
                                          model: {
                                            value: _vm.msg.value,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.msg, "value", $$v)
                                            },
                                            expression: "msg.value",
                                          },
                                        },
                                        _vm._l(6, function (item) {
                                          return _c("el-option", {
                                            key: item.brandId,
                                            attrs: {
                                              label: item.brandName,
                                              value: item.brandId,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "marL10",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteBtn(
                                                "att",
                                                index,
                                                inx
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "marT10 x-start" },
                                    [
                                      _vm._l(att.tags, function (tag, tagI) {
                                        return _c(
                                          "el-tag",
                                          {
                                            key: tagI,
                                            staticClass: "marR10",
                                            attrs: { closable: "" },
                                            on: {
                                              close: function ($event) {
                                                return _vm.closeTag(
                                                  index,
                                                  inx,
                                                  tagI
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  tag.title + " ￥" + tag.price
                                                )
                                            ),
                                          ]
                                        )
                                      }),
                                      _c("el-button", {
                                        attrs: {
                                          size: "mini",
                                          icon: "el-icon-plus",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addTag(index, inx)
                                          },
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                ])
                              }),
                              _c(
                                "div",
                                { staticClass: "marT10" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                        icon: "el-icon-plus",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addproperty(index)
                                        },
                                      },
                                    },
                                    [_vm._v("添加属性")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }