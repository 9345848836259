<template>
  <div>
    <el-dialog
      title="批量修改上架商品信息"
      :visible.sync="show"
      width="800px"
      style="height: 800px"
      :before-close="handleClose"
    >
      <div class="x-c" style="height: 400px">
        <div class="y-f">
          <el-checkbox-group v-model="checkList" @change="checkChange">
            <div class="x-f marB15">
              <el-checkbox
                class="x-f marR15"
                label="商城分类："
                @change="checkChange"
              ></el-checkbox>
              <el-input v-model="categoryName" :disabled="isLassify">
                <i slot="suffix" class="el-icon-more" @click="selectGoods"></i>
              </el-input>
            </div>
            <!-- <div class="x-f marB15">
                            <el-checkbox class="x-f marR15" label="商品品牌：" @change="checkChange"></el-checkbox>
                            <el-select v-model="form.brandId" class="inputWidth" placeholder="请选择"
                                       :disabled="isBrand"
                            >
                                <el-option
                                    v-for="item in BrandList"
                                    :key="item.brandId"
                                    :label="item.brandName"
                                    :value="item.brandId"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="x-f marB15">
                            <el-checkbox class="x-f marR15" label="运费模板：" @change="checkChange"></el-checkbox>
                            <el-select v-model="form.templateId" class="inputWidth" placeholder="请选择"
                                       :disabled="isFreight"
                            >
                                <el-option
                                    v-for="item in TempData"
                                    :key="item.templateId"
                                    :label="item.templateName"
                                    :value="item.templateId"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="x-f marB15">
                            <el-checkbox class="x-f marR15" label="起购数量：" @change="checkChange"></el-checkbox>
                            <el-input class="inputWidth" type="number" v-model="form.minimumPurchaseQuantity"
                                      :disabled="isStartNum"
                            ></el-input>
                        </div>-->
          </el-checkbox-group>
          <!--
                    <div class="x-start">
                        <span class="marR15">是否按最低购买数量倍增</span>
                        <el-radio v-model="radio" label="1">是</el-radio>
                        <el-radio v-model="radio" label="0">否</el-radio>
                    </div>
                    <div class="marT15" style="color: #c3c3c3">说明：勾选后没有选择具体值相当于清空内容</div> -->
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!--        商城分类-->
    <el-dialog
      :title="isChangeAll ? '批量修改上架商品信息' : '选择商城分类'"
      :visible.sync="showSon"
      width="30%"
      :before-close="sonClose"
    >
      <div style="max-height: 300px; overflow-y: auto">
        <el-tree
          :data="classifyData"
          :props="defaultProps"
          default-expand-all
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sonClose">取 消</el-button>
        <el-button type="primary" @click="sonOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TreeAndTable from '@/views/O2OMall/goods/components/treeAndTable.vue'
import { categoryList } from '@/api/O2OMall/goods/category'
import { batchUpdateGoods } from '@/api/O2OMall/goods/list'
import { listBrand } from '@/api/goods/brand'
import { templateList } from '@/api/O2OMall/manage/freight'
import SelectTree from '@/views/O2OMall/goods/components/selectTree.vue'

export default {
  name: 'batchEditGoods',
  components: {
    TreeAndTable,
    SelectTree
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    selectData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isChangeAll: false,
      checkList: [],
      radio: '0',
      form: {
        brandId: '',
        categoryId: null,
        goodsIds: [],
        isDoubly: true,
        templateId: '',
        minimumPurchaseQuantity: ''
      },
      categoryName: null,
      item: null, // 选择的弹框

      isLassify: true, // 分类禁用
      isBrand: true, // 商品品牌禁用
      isFreight: true, // 运费模板禁用
      isStartNum: true, // 起购数量禁用
      // 子弹框
      showSon: false,

      dialogTableData: [],
      dialogTableSelect: [], // 弹框列表选择数据
      queryParams: {
        pageSize: 15,
        pageNum: 1,
        total: 0
      },
      classifyData: null, // 分类列表
      BrandList: null, // 品牌列表
      TempData: null, // 运费模板
      defaultProps: {
        children: 'children',
        label: 'label',
        id: 'id'
      }
    }
  },
  created () {
    console.log('------------------0000000-：', this.goods)
    this.getClassifyList()
    this.listBrand()
    this.getTemplate()
  },
  methods: {
    // 商品分类
    async getClassifyList () {
      await categoryList().then(res => {
        // console.log('分类列表1：', res.rows)
        this.classifyData = this.recursiveToTree(res.data, 0)
        // console.log('分类列表2：', JSON.stringify(this.classifyData))
      })
    },
    // 列表转换树形列表
    recursiveToTree (data, parentId) {
      const tree = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].parentId == parentId) {
          const node = {
            id: data[i].categoryId,
            label: data[i].categoryName,
            children: this.recursiveToTree(data, data[i].categoryId)
          }
          tree.push(node)
        }
      }
      return tree
    },
    //商品品牌
    async listBrand () {
      try {
        this.loading = true //加载框
        const res = await listBrand({
          pageNum: 1,
          pageSize: 999
        }) //获取数据
        if (res.total != 0) {
          this.BrandList = res.rows
        }
      } catch (error) {
        console.log('失败')
      }
    },
    // 运费模板
    async getTemplate () {
      const res = await templateList()
      this.TempData = res.rows
    },

    normalizer (node) {
      console.log('node:', node)
      if (node.children == null || node.children == 'null') {
        delete node.children
      }
      console.log(node)
      return {
        id: node.categoryId,
        label: node.categoryName,
        children: node.children
      }
    },
    handleClose () {
      this.$emit('update:show', false)
    },
    // 选择分类
    // selectclassify() {
    //     if (!this.isLassify) {
    //         this.item = 1
    //         this.showSon = true
    //         this.sonTitle = '商城分类'
    //     }
    //
    // },
    // 商品品牌
    // selectbrand() {
    //     if (!this.isBrand) {
    //         this.item = 2
    //         this.showSon = true
    //         this.sonTitle = '商品品牌'
    //     }
    // },
    // 运费模板
    // freightTemp() {
    //     if (!this.isFreight) {
    //         this.item = 3
    //         this.showSon = true
    //         this.sonTitle = '运费模板'
    //     }
    // },
    // 关闭子弹窗
    sonClose () {
      this.showSon = false
    },
    submit () {
      this.selectData.forEach(item => {
        this.form.goodsIds.push(item.goodsId)
      })
      batchUpdateGoods(this.form).then(res => {
        console.log('批量修改提交：', res)
        this.$message.success('修改成功')
        this.reset()
        this.$emit('update:show', false)
        this.$emit('close')
      })
    },
    // 子弹窗确定
    sonOk () {
      this.showSon = false
    },
    checkChange (val, event) {
      // console.log('选择：', this.checkList)
      // console.log('选择2：', val)
      this.isLassify = this.isStrInArray('商城分类：', val) ? false : true
      this.isBrand = this.isStrInArray('商品品牌：', val) ? false : true
      this.isFreight = this.isStrInArray('运费模板：', val) ? false : true
      this.isStartNum = this.isStrInArray('起购数量：', val) ? false : true
    },
    isStrInArray (str, arr) {
      let n = arr.length
      for (let i = 0; i < n; i++) {
        if (arr[i] == str) {
          return true
        }
      }
      return false
    },
    // 重置
    reset () {
      this.isLassify = true
      this.isBrand = true
      this.isFreight = true
      this.isStartNum = true
      this.checkList = []
      this.categoryName = ''
      this.form = {
        brandId: '',
        categoryId: null,
        goodsIds: [],
        isDoubly: true,
        templateId: '',
        minimumPurchaseQuantity: ''
      }
    },
    handleNodeClick (val) {
      console.log('弹框树：', val)
      this.form.categoryId = val.id
      this.categoryName = val.label
    },
    //  打开分类弹窗
    selectGoods () {
      this.showSon = true
    }
  }
}
</script>

<style lang="scss" scoped>
.inputWidth {
  width: 300px;
}
</style>
