<template>
  <div class="container">
    <div style="width: 100%">
      <cardTitleCom cardTitle="商城分类">
        <template class="padd15" #cardContent>
          <div class="padd15">
            <div class="btnList bg-Color x-start">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-plus"
                @click="addlist('add')"
                >新增
              </el-button>
              <!-- <el-button
                                type="primary"
                                style="background: #aa25fe; border-color: #aa25fe; color: #fff"
                                size="mini"
                                icon="el-icon-plus"
                                @click="synchronizationBtn"
                            >同步商品类别
                            </el-button> -->
              <el-button
                type="danger"
                plain
                icon="el-icon-delete"
                size="mini"
                @click="deleteBtn"
                >删除
              </el-button>
              <!--                            <c-button test="删除" icon="el-icon-delete" bgColor="#fb5f56" @click="deleteBtn"></c-button>-->
            </div>
            <div>
              <el-table
                :data="tableData"
                border
                height="550px"
                width="100%"
                row-key="categoryId"
                :tree-props="{
                  children: 'children',
                  hasChildren: 'hasChildren',
                }"
                @selection-change="historySelectionChange"
              >
                <el-table-column type="selection" align="center" width="55" />
                <el-table-column prop="categoryName" label="商城分类名称" width="180" />
                <el-table-column
                  prop="categoryNo"
                  label="商城分类编号"
                  align="center"
                  width="220"
                >
                  <template slot-scope="scope">
                    <el-button type="text" @click.stop="addlist('edit', scope.row)"
                      >{{ scope.row.categoryNo }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="商城分类图片"
                  align="center"
                  width="180"
                >
                  <template slot-scope="scope">
                    <el-image
                      @click="showBigImage(scope.row.imageUrl)"
                      :src="scope.row.imageUrl"
                      style="width: 60px; height: 60px"
                    ></el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="排序" align="center" width="180">
                  <template slot-scope="scope">
                    <el-button type="text" @click="moveUp(scope.row.categoryId)"
                      >上移
                    </el-button>
                    <el-button type="text" @click="moveDown(scope.row.categoryId)"
                      >下移
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" />
              </el-table>
            </div>
            <div class="pagination-footer x-bc">
              <div>
                已选
                <span style="color: #1c75f4">{{ selectData.length }}</span>
                条
              </div>
              <pagination
                :total="queryParams.total"
                :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize"
                @pagination="getList"
              />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <el-dialog
      width="30%"
      title="新增/编辑商城分类编号"
      :visible.sync="addSerialNumber"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="diaForm" :rules="rules" label-width="80px">
        <el-form-item label="上级类别" prop="parentId">
          <Treeselect
            v-model="diaForm.parentId"
            :options="categoryTreeOptions"
            :show-count="true"
            placeholder="请选择上级类别"
            :normalizer="normalizer"
          />
        </el-form-item>
        <el-form-item label="类别编码" prop="categoryNo">
          <el-input
            v-model.number="diaForm.categoryNo"
            placeholder="请输入类别编码"
            maxlength="11"
          />
        </el-form-item>
        <el-form-item label="类别名称" prop="categoryName">
          <el-input
            v-model="diaForm.categoryName"
            placeholder="请输入类别名称"
            maxlength="30"
          />
        </el-form-item>
        <!-- <el-form-item label="排序编号" prop="sortNo">
          <el-input
            v-model.number="diaForm.sortNo"
            placeholder="请输入排序编号"
            maxlength="30"
          />
        </el-form-item> -->
        <el-form-item label="备注" prop="remark">
          <el-input
            type="text"
            v-model="diaForm.remark"
            placeholder="备注长度介于 1 和 30 字符之间"
            maxlength="30"
          />
        </el-form-item>
        <el-form-item label="分类图标">
          <OssUpload
            v-model="diaForm.imageUrl"
            :option="{
              listType: 'img',
              type: ['jpg', 'png'],
              size: '2 mb',
              tip: '建议图标尺寸100*100px、背景做透明处理，',
              autoUpload: true,
            }"
          />
        </el-form-item>
        <el-form-item label="分类海报">
          <OssUpload
            v-model="diaForm.posterImageUrl"
            :option="{
              listType: 'img',
              type: ['jpg', 'png'],
              size: '2 mb',
              autoUpload: true,
            }"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addDialogOk">保 存</el-button>
        <el-button type="danger" @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 图片放大对话框 -->
    <el-dialog :visible.sync="dialogImageVisible" width="30%">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import CButton from "@/views/components/c-button/index.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import OssUpload from "@/components/oss-upload/index.vue";
import {
  syncGoodsCategory,
  categoryPageList,
  categorySave,
  categoryUpdate,
  categoryUpdateSortNo,
  categoryRemove,
} from "@/api/O2OMall/goods/category";

import { categoryLiStTree } from "@/api/O2OMall/goods/list";

export default {
  name: "index",
  dicts: ["App_is_pos_visible"],
  components: {
    cardTitleCom,
    CButton,
    Treeselect,
    OssUpload,
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/open/oss/file/upload",
      tableData: [],
      queryParams: {
        total: 0,
        // pageSize: 15,
        // pageNum: 1,
        limit: 15,
        page: 1,
      },
      iconFileList: [], // 编辑时预览的图标
      posterFileList: [], // 编辑时预览的海报
      selectData: [],
      iconRefresh: "el-icon-refresh",
      // 图片放大可见属性设置
      dialogImageVisible: false,

      // 图片放大url属性设置
      dialogImageUrl: null,

      // 添加编辑框
      addSerialNumber: false,
      diaForm: {
        categoryNo: undefined,
        categoryName: undefined,
        parentId: undefined,
        isPosVisible: undefined,
        sortNo: undefined,
        remark: undefined,
        categoryId: undefined,
        imageUrl: undefined,
        posterImageUrl: undefined,
      },
      // 表单校验
      rules: {
        parentId: [{ required: true, message: "上级类别不能为空", trigger: "blur" }],
        categoryName: [{ required: true, message: "类别名称不能为空", trigger: "blur" }],
        categoryNo: [{ required: true, message: "类别编号不能为空", trigger: "blur" }],
        sortNo: [{ type: "number", message: "排序需为数字" }],
      },
      // 分类树选项
      categoryTreeOptions: null,
      diatype: "",
    };
  },
  mounted() {
    this.getList(this.queryParams);
  },
  methods: {
    //treeSelect结构属性转换
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    historySelectionChange(val) {
      console.log("选择长度：", val);
      this.selectData = val;
    },
    async getList(val) {
      console.log(val, "val");
      let params = {
        pageNum: val.page,
        pageSize: val.limit,
      };
      this.queryParams.page = val.page;
      this.queryParams.limit = val.page;
      await categoryPageList(params).then((res) => {
        console.log("分类列表1：", res.data);
        this.tableData = this.handleTree(res.rows, "categoryId");
        this.queryParams.total = res.total;
        // this.tableData = this.recursiveToTree(res.data, 0);
        // console.log('分类列表2：', this.tableData)
      });
    },
    // 列表转换树形列表
    recursiveToTree(data, parentId) {
      const tree = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].parentId == parentId) {
          const node = {
            parentId: data[i].parentId,
            categoryId: data[i].categoryId,
            categoryNo: data[i].categoryNo,
            categoryName: data[i].categoryName,
            imageUrl: data[i].imageUrl,
            posterImageUrl: data[i].posterImageUrl,
            sortNo: data[i].sortNo,
            remark: data[i].remark,
            children: this.recursiveToTree(data, data[i].categoryId),
          };
          tree.push(node);
        }
      }
      return tree;
    },
    // 新增/编辑按钮
    async addlist(type, item) {
      this.diatype = type;
      this.loading = this.$loading({
        lock: true,
        text: "提交中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.1)",
        customClass: "topLoading",
      });
      try {
        const res = await categoryLiStTree({ isCategoryOutThree: true });
        if (res.data?.[0]) {
          let children = undefined;
          if (res.data[0].children) {
            children = res.data[0].children.map((x) => ({
              ...x,
              children: undefined,
            }));
          }
          this.categoryTreeOptions = [{ ...res.data[0], children }];
        }
      } catch (error) {}
      this.loading.close();
      if (type == "edit") {
        console.log("item--", item);
        this.diaForm = {
          categoryNo: item.categoryNo,
          categoryName: item.categoryName,
          parentId: item.parentId,
          isPosVisible: item.isPosVisible,
          sortNo: item.sortNo,
          remark: item.remark,
          categoryId: item.categoryId,
          imageUrl: item.imageUrl,
          posterImageUrl: item.posterImageUrl,
        };
        if (item.imageUrl != null) {
          this.iconFileList =
            this.iconFileList.length > 0 ? this.iconFileList : [{ url: item.imageUrl }];
        }
        if (item.posterImageUrl != null) {
          this.posterFileList =
            this.posterFileList.length > 0
              ? this.posterFileList
              : [{ url: item.posterImageUrl }];
        }
      }
      this.addSerialNumber = true;
    },
    // 同步按钮
    async synchronizationBtn() {
      const res = await syncGoodsCategory();
      // 请求列表
      await this.getList(this.queryParams);
      this.$modal.msgSuccess("同步成功");
    },
    // 删除按钮
    deleteBtn() {
      console.log("选择长度：", this.selectData);
      if (this.selectData.length > 0) {
        let categoryIds = [];
        this.selectData.forEach((item) => {
          categoryIds.push(item.categoryId);
        });
        this.$confirm(`你确定要删除已选择的 ${categoryIds.length} 个分类吗？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            categoryRemove(categoryIds).then((res) => {
              this.$modal.msgSuccess("删除成功");
              this.getList(this.queryParams);
            });
          })
          .catch(() => {
            this.$modal.msg("已取消删除");
          });
      }
    },
    // 上移
    async moveUp(categoryId) {
      await categoryUpdateSortNo({
        categoryId: categoryId,
        sortType: 1,
      });
      this.$modal.msgSuccess("上移成功");
      await this.getList(this.queryParams);
    },
    // 下移
    async moveDown(categoryId) {
      await categoryUpdateSortNo({
        categoryId: categoryId,
        sortType: -1,
      });
      this.$modal.msgSuccess("下移成功");
      await this.getList(this.queryParams);
    },
    // 添加/编辑弹框确定
    async addDialogOk() {
      console.log("添加编辑：", this.diaForm);
      if (this.diatype == "add") {
        await categorySave(this.diaForm);
        this.$modal.msgSuccess("新增成功");
      } else {
        await categoryUpdate(this.diaForm);
        this.$modal.msgSuccess("修改成功");
      }
      await this.getList(this.queryParams);
      this.addSerialNumber = false;
      this.iconFileList = [];
      this.restore();
    },
    // 关闭弹框
    handleClose() {
      this.restore();
      this.addSerialNumber = false;
    },
    // 情况弹框表单
    restore() {
      this.diaForm = {
        categoryNo: undefined,
        categoryName: undefined,
        parentId: undefined,
        isPosVisible: undefined,
        sortNo: undefined,
        remark: undefined,
        categoryId: undefined,
        imageUrl: undefined,
      };
    },
    /** 图片放大处理 */
    showBigImage(url) {
      this.dialogImageUrl = url;
      this.dialogImageVisible = true;
    },
    /** 上传分类图标 */
    handleIconSuccess(file) {
      this.diaForm.imageUrl = file.accessUrl;
      this.iconFileList[0].url = file.accessUrl;
      this.iconFileList[0].fileName = file.fileName;
      this.$forceUpdate();
    },
    /** 分类海报上传成功 */
    handleposterSuccess(file) {
      console.log("上传图片成功： ", file.accessUrl);
      this.diaForm.posterImageUrl = file.accessUrl;
      this.posterFileList[0].url = file.accessUrl;
      this.posterFileList[0].fileName = file.fileName;
      this.$forceUpdate();
    },
    // 删除海报
    deleteImg(num) {
      if (num == 1) {
        this.diaForm.imageUrl = "";
      } else {
        this.diaForm.posterImageUrl = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  background: #e9e9e9;

  .btnList {
    padding: 10px;
  }

  .tableBox {
    padding: 10px;
  }

  .avatar {
    width: 6.25vw;
    height: 12vh;
    display: block;
  }
}
</style>
