<template>
  <el-dialog
    title="选择分类"
    :visible="openCategory"
    :before-close="close"
    v-dialogDrag="true"
    width="30%"
    v-loading="formLoading"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div style="height: 230px">
      <el-form :model="form" :rules="rules" ref="form" label-width="80px">
        <el-form-item label="分类" prop="categoryId">
          <Treeselect
            v-model="form.categoryId"
            :options="mallCategoryTreeOptions2"
            :show-count="true"
            placeholder="请选择分类"
            :normalizer="normalizer1"
            :open="getMallCategory"
            maxHeight="180"
            @select="select"
          />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  copyBasisGoodsToMall,
  categoryLiStTree,
  batchUpdateGoods,
} from "@/api/O2OMall/goods/list";
import {
  copyBasisGoodsToOrdermeal,
  dianCanBatchUpdateGoods,
  categoryLiStTree as categoryLiStTreeDianCan,
} from "@/api/O2OThird/orderMealWxa/goods";

import {
  cakefreeselectGoodsSyncBasisGoods,
  cakefreeselectCategoryLiStTree,
  cakefreeselectGoodsBatchUpdate,
} from "@/api/O2OThird/cakeFreeSelectApp/goods";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "category",
  props: {
    check: {
      type: Array,
      default: [],
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    openCategory: {
      type: Boolean,
      default: false,
    },
    isDianCan: {
      type: Boolean,
      default: false,
    },
    isCake: {
      type: Boolean,
      default: false,
    },
  },
  components: { Treeselect },
  data() {
    return {
      form: {
        categoryId: undefined,
      },
      rules: {
        categoryId: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
      },
      mallCategoryTreeOptions: [],
      mallCategoryTreeOptions2: [],
      formLoading: false,
    };
  },
  watch: {
    openCategory: {
      handler(val) {
        if (val) {
          this.form.categoryId = undefined;
          this.getMallCategory();
        }
      },
    },
  },
  methods: {
    cancel() {
      this.$emit("update:openCategory", false);
    },
    submit() {
      let isSubmit = this.isSubmit;
      let url = undefined;
      if (this.isDianCan) {
        url = !isSubmit ? dianCanBatchUpdateGoods : copyBasisGoodsToOrdermeal;
      } else if (this.isCake) {
        url = !isSubmit
          ? cakefreeselectGoodsBatchUpdate
          : cakefreeselectGoodsSyncBasisGoods;
      } else {
        url = !isSubmit ? batchUpdateGoods : copyBasisGoodsToMall;
      }
      url({
        goodsIds: [...this.check],
        categoryId: this.form.categoryId,
      }).then(async (res) => {
        await this?.$parent?.$refs?.tablePage?.getList();
        this.$modal.msgSuccess(
          !this.isSubmit ? "批量修改成功" : "保存成功，请到未上架商品查看"
        );
        this.$emit("update:openCategory", false);
      });
    },
    getMallCategory() {
      let url = undefined;
      if (this.isDianCan) {
        url = categoryLiStTreeDianCan;
      } else if (this.isCake) {
        url = cakefreeselectCategoryLiStTree;
      } else {
        url = categoryLiStTree;
      }
      url({ isCategoryOutThree: true }).then((response) => {
        this.mallCategoryTreeOptions = response.data;
        if (
          response.data?.length === 1 &&
          response.data?.[0]?.children?.length
        ) {
          this.mallCategoryTreeOptions2 = response.data[0].children;
        } else {
          this.mallCategoryTreeOptions2 = [];
        }
      });
    },
    normalizer1(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    select(e) {
      if (e.disableBranchNodes) {
        return false;
      }
    },
    close() {
      this.$emit("update:openCategory", false);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect_menu {
  height: 200px !important;
  overflow: scroll;
}
</style>
