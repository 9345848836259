<template>
  <el-dialog
    title="门店价格策略"
    :visible.sync="show"
    width="50%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <el-table
        ref="multipleTable"
        :data="shoplist"
        border
        height="60vh"
        style="width: 100%; max-height: 540px"
        @selection-change="handleSelectionChange"
        row-key="shopId"
      >
        <el-table-column type="index"></el-table-column>
        <el-table-column type="selection" width="90" show-overflow-tooltip />
        <el-table-column
          prop="shopName"
          align="center"
          label="门店"
          width="180"
          show-overflow-tooltip
        />

        <el-table-column
          v-for="(item, index) in tableHander"
          :key="index"
          align="center"
          :label="item.label"
          :prop="item.prop"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-input size="mini" v-model="scope.row[item.prop]"></el-input>
          </template>
        </el-table-column>

        <el-table-column align="center" label="是否使用实时库存" min-width="100">
          <template slot-scope="scope">
            <el-switch
              :disabled="updateDisabled"
              v-model="scope.row.stockUseMode"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
            <!-- ( 0 虚拟库存 1 实时库存)-->
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="虚拟库存"
          prop="stockQuantity"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.stockQuantity"
              :controls="false"
              :precision="0"
              :step="1"
              :min="0"
              :disabled="scope.row.stockUseMode == 1"
              label="请输入虚拟库存"
              style="text-align: left"
              size="mini"
            ></el-input-number>
          </template> 
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { shopInfoList } from "@/api/O2OMall/manage/shop";
import { deepCopy } from "@/utils";
export default {
  name: "shopSpecDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    shopMatchingItems: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
      tableHander: [
        { label: "价格", prop: "salePrice" },
        { label: "划线价格", prop: "liningPrice" },
      ],
      shoplist: [],
      check: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 100,
        status: 0,
      },
    };
  },
  created() {
    // this.getList()
  },
  watch: {
    show: {
      handler(e) {
        if (e) {
          this.loading = true;
          this.getList();
        } else {
          this.loading = false;
          this.shoplist = [];
        }
      },
    },
  },
  methods: {
    // 确定提交
    submit() {
      const newShopList = deepCopy(this.shoplist).map((x) => ({
        ...x,
        isOnline: Boolean(this.check.find((y) => y.shopId === x.shopId)),
        stockQuantity: Number(x.stockQuantity),
        salePrice: Number(x.salePrice || 0),
        liningPrice: Number(x.liningPrice || 0),
      }));
      const item = newShopList.find(
        (x) => x.isOnline && (!x.salePrice || !x.stockQuantity)
      ); // stockQuantity
      if (item && !item.salePrice) {
        return this.$message.error(`门店:${item.shopName},上架状态下售价不能为空!`);
      }
      if (item && !item.stockQuantity && item.stockUseMode != 1) {
        return this.$message.error(`门店:${item.shopName},上架状态下库存不能为空!`);
      }
      this.$emit("selectShopOk", newShopList);
    },
    handleClose() {
      this.$emit("closeDia");
    },
    //  列表选择数据
    handleSelectionChange(val) {
      if (!this.loading) {
        console.log("选择的数据：", val);
        this.check = val;
      }
    },
    getList() {
      this.loading = true;
      shopInfoList(this.queryParams).then((response) => {
        const isNewShop =
          this.shopMatchingItems.shopPriceItems.length === response.length ||
          !this.shopMatchingItems.shopPriceItems.length;
        this.shoplist = response.rows.map((x) => {
          const item = this.shopMatchingItems.shopPriceItems?.find(
            (y) => x.shopId === y.shopId
          );
          return {
            stockUseMode: item
              ? item?.stockUseMode
              : this.shopMatchingItems.stockUseMode || undefined,
            shopId: x.shopId,
            shopName: x.shopName,
            salePrice: item
              ? item?.salePrice
              : this.shopMatchingItems.salePrice || undefined,
            liningPrice: item
              ? item?.liningPrice
              : this.shopMatchingItems.liningPrice || undefined,
            stockQuantity: item
              ? item?.stockQuantity
              : this.shopMatchingItems.stockQuantity || undefined,
            isOnline:
              item?.isOnline || (item?.isOnline === false ? item.isOnline : isNewShop),
            unitId: this.shopMatchingItems.unitId,
          };
        });
        this.$nextTick(() => {
          this.check = [];
          this.shoplist.forEach((x) => {
            if (x.isOnline) {
              this.check.push(x);
              this.$refs.multipleTable.toggleRowSelection(x);
            }
          });
        });
        this.$nextTick(() => {
          this.loading = false;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__header-wrapper .el-checkbox__input::after {
  content: "是否上架";
  position: absolute;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 550;
}
</style>
