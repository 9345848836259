var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "dialogDrag",
          rawName: "v-dialogDrag",
          value: true,
          expression: "true",
        },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      attrs: {
        title: "选择分类",
        visible: _vm.openCategory,
        "before-close": _vm.close,
        width: "30%",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { height: "230px" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类", prop: "categoryId" } },
                [
                  _c("Treeselect", {
                    attrs: {
                      options: _vm.mallCategoryTreeOptions2,
                      "show-count": true,
                      placeholder: "请选择分类",
                      normalizer: _vm.normalizer1,
                      open: _vm.getMallCategory,
                      maxHeight: "180",
                    },
                    on: { select: _vm.select },
                    model: {
                      value: _vm.form.categoryId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "categoryId", $$v)
                      },
                      expression: "form.categoryId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }