<template>
  <div class="wrap">
    <!-- <draggable
      v-model="treeData"
      group="items"
      @start="drag=true"
      @end="drag=false"
    >
      <div
        v-for="(item, index) in treeData"
        :key="index"
      >
        <div>{{ item.name }}</div>
        <draggable v-model="item.children" group="items" @start="drag=true" @end="drag=false">
          <div v-for="(child, idx) in item.children" :key="idx">
            {{ child.name }}
          </div>
        </draggable>
      </div>
    </draggable> -->
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import TablePage from "@/components/tablePage";
import Dialog from "@/components/Dialog";
import {
  categoryList,
  categoryRemove,
  categoryUpdateSortNo,
  categoryReduction,
} from "@/api/O2OMall/goods/category";

export default {
  name: "QueryManSet",
  components: { TablePage, Dialog, draggable },
  data() {
    return {
      drag: false,
      treeData: [
        {
          name: 'Item 1',
          children: [
            { name: 'Subitem 2.1' },
            { name: 'Subitem 2.2' }
          ]
        },
        {
          name: 'Item 2',
          children: [
            { name: 'Subitem 2.1' },
            { name: 'Subitem 2.2' }
          ]
        }
      ],
      dialogOptions: {
        title: "",
        width: 620,
        show: false,
        type: "O2OGoodsCategory",
        formData: null,
      },
      options: {
        // classTitle: true, // 多选
        mutiSelect: true, // 多选
        loading: true,
        getListApi: categoryList,
        getDataKey: (res) => {
          return {
            list: this.handleTree(res.data, "categoryId"),
          };
        },
        body: {},
        title: "营业通账号管理",
        check: [], // 选中数据
        rowKey: "categoryId",
        tableTitle: true,
        hidePagination: true,
        treeProps: {
          children: "children",
          hasChildren: "hasChildren",
        },
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            icon: "el-icon-delete",
            alertText: "确认要删除选中商品分类吗？",
            type: "danger",
            btnType: "dropdown",
            trigger: "click", // 要求
            other: [
              {
                label: "回收站",
                collectionDialog: {
                  restoreListApi: categoryReduction,
                  dcollectionBody: {
                    delFlag: "1",
                  },
                },
              },
            ],
          },
        ],
        columns: [
          {
            prop: "categoryName",
            label: "商城分类名称",
            align: "left",
            minWidth: 160,
          },
          {
            prop: "categoryNo",
            label: "商城分类编号",
            type: "link",
            click: "update",
            minWidth: 160,
          },
          {
            prop: "imageUrl",
            label: "商城分类图片",
            type: "image",
            minWidth: 100,
          },
          {
            prop: "sort",
            label: "排序",
            type: "button",
            button: [
              { text: "上移", click: "up" },
              { text: "下移", click: "down" },
            ],
            minWidth: 80,
          },
          {
            prop: "remark",
            align: "left",
            label: "备注",
            minWidth: 360,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    updateIndex(routeprocessList) {
      routeprocessList.forEach((item, index) => {
        item.orderNum = index + 1;
      });
    },
    async handleEvent(type, row) {
      switch (type) {
        case "handleChoose":
          console.log('handleChoose,handleChoose' );
          this.copyList = this.options.list
          console.log(this.options.list[row.oldIndex], 'handleChoose', 'handleChoose.options.list')
          console.log(this.options.list, row, 'handleChoose', 'handleChoose.options.list')
          break;
        case "handleMove":
          console.log('handleMove, 移动中' );
          // this.copyList = this.options.list
          console.log(this.options.list, row, 'handleMove', 'handleMove.options.list')
          break;
        case "dragTableEnd":
          console.log(type, row, 'type, row')
          const { newIndex, oldIndex } = row;
          const self = this;
          console.log(newIndex, newIndex, 'newIndex, newIndex')
          console.log(oldIndex, oldIndex, 'oldIndex, oldIndex')
          // const targetRow = self.options.list.splice(oldIndex, 1)[0];
          // self.options.list.splice(newIndex, 0, targetRow);

          // let newIndex = row.newIndex  // 排序后的索引位置
          // let oldIndex = row.oldIndex  // 排序前的索引位置
          // console.log(type, row, 'type, row')
          // console.log(newIndex, 'newIndex', '排序后的索引位置')
          // console.log(oldIndex, 'oldIndex', '排序前的索引位置')
          // console.log(this.options.list, 'dragTableEnd', 'this.options.list')
          // this.options.list = this.copyList
          // this.$nextTick(function () {
          //   this.options.list  = this.copyList;
          // });
          // console.log(this.options.list, 'dragTableEnd', 'this.options.list')

          // this.tableData.splice(
          //     newIndex,
          //     0,
          //     this.tableData.splice(oldIndex, 1)[0]
          // );
          // var newArray = this.tableData.slice(0);
          // this.tableData = [];

                        
          // // 写完以上部分就已经可以实现基本的拖拽功能了，以下是拓展
          // //始拖拽事件
          // onEnd: function (/**Event*/evt) {
          //     let newIndex = evt.newIndex  // 排序后的索引位置
          //     let oldIndex = evt.oldIndex  // 排序前的索引位置
          //     var itemEl = evt.item;  // 拖拽 HTMLElement
          //     evt.to;    // 目标表
          //     evt.from;  // 上一个列表
          //     evt.oldIndex;  // 元素在旧父级中的旧索引
          //     evt.newIndex;  // 元素在新父级中的新索引
          //     evt.clone // 克隆元件
          //     evt.pullMode;  // 当项目在另一个可排序表中时：`“clone”`如果克隆，`true`如果移动
          // },
          // //点击选中元素事件
          // onChoose: function (/**Event*/evt) { 
          //   console.log(evt, 'onChoose');
          //     evt.oldIndex; 
          // }, 
          // //取消选中事件
          // onUnchoose: function (/**Event*/evt) {
          //   console.log(evt, 'onUnchoose');
          // },

          //     let newIndex = evt.newIndex  // 排序后的索引位置
          //     let oldIndex = evt.oldIndex  // 排序前的索引位置
          //     var itemEl = evt.item;  // 拖拽 HTMLElement
          //     evt.to;    // 目标表
          //     evt.from;  // 上一个列表
          //     evt.oldIndex;  // 元素在旧父级中的旧索引
          //     evt.newIndex;  // 元素在新父级中的新索引
          //     evt.clone // 克隆元件
          //     evt.pullMode;  // 当项目在另一个可排序表中时：`“clone”`如果克隆，`true`如果移动

          // console.log(item.newIndex, item.oldIndex, 'newIndex, oldIndex onEnd');
          // // 拖拽排序数据
          // that.list.splice(item.newIndex, 0, that.list.splice(item.oldIndex, 1)[0])
          // const newArray = that.list.slice(0)
          // // that.routeprocessList = [] // 必须有此步骤，不然拖拽后回弹
          // that.$nextTick(function () {
          //   that.list = newArray // 重新赋值，用新数据来刷新视图
          //   this.updateIndex(that.list)//更改列表中的序号，使序号1.2.3.4.....显示，不然就是行拖拽后乱序显示如:3.2.4.1...
          // })
          // this.$refs.tablePage.getList();
          break;
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "up":
          try {
            await categoryUpdateSortNo({
              categoryId: row.categoryId,
              sortType: 1,
            });
            this.$modal.msgSuccess("上移成功");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "down":
          try {
            await categoryUpdateSortNo({
              categoryId: row.categoryId,
              sortType: -1,
            });
            this.$modal.msgSuccess("下移成功");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "update":
          {
            const options = {
              show: true,
              title: "编辑商品分类",
              click: type,
              formData: row,
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "add":
          {
            const options = {
              show: true,
              title: "新增商品分类",
              click: type,
              formData: {},
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "del": // 选中数据 Array
          const categoryIds = this.options.check.map((x) => x.categoryId);
          categoryRemove(categoryIds).then((res) => {
            this.$modal.msgSuccess("删除成功");
            this.$refs.tablePage.getList();
          });
          break;
        default:
          break;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.wrap {
  .dialog-footer {
    height: 80px;
  }
}
</style>
