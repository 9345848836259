var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticStyle: { width: "100%" } },
        [
          _c("cardTitleCom", {
            attrs: { cardTitle: "商城分类" },
            scopedSlots: _vm._u([
              {
                key: "cardContent",
                fn: function () {
                  return [
                    _c("div", { staticClass: "padd15" }, [
                      _c(
                        "div",
                        { staticClass: "btnList bg-Color x-start" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-plus",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addlist("add")
                                },
                              },
                            },
                            [_vm._v("新增 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "danger",
                                plain: "",
                                icon: "el-icon-delete",
                                size: "mini",
                              },
                              on: { click: _vm.deleteBtn },
                            },
                            [_vm._v("删除 ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.tableData,
                                border: "",
                                height: "550px",
                                width: "100%",
                                "row-key": "categoryId",
                                "tree-props": {
                                  children: "children",
                                  hasChildren: "hasChildren",
                                },
                              },
                              on: {
                                "selection-change": _vm.historySelectionChange,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  align: "center",
                                  width: "55",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "categoryName",
                                  label: "商城分类名称",
                                  width: "180",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "categoryNo",
                                  label: "商城分类编号",
                                  align: "center",
                                  width: "220",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.addlist(
                                                  "edit",
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.categoryNo) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "name",
                                  label: "商城分类图片",
                                  align: "center",
                                  width: "180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "60px",
                                            height: "60px",
                                          },
                                          attrs: { src: scope.row.imageUrl },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showBigImage(
                                                scope.row.imageUrl
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "name",
                                  label: "排序",
                                  align: "center",
                                  width: "180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.moveUp(
                                                  scope.row.categoryId
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("上移 ")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.moveDown(
                                                  scope.row.categoryId
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("下移 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "remark", label: "备注" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pagination-footer x-bc" },
                        [
                          _c("div", [
                            _vm._v(" 已选 "),
                            _c("span", { staticStyle: { color: "#1c75f4" } }, [
                              _vm._v(_vm._s(_vm.selectData.length)),
                            ]),
                            _vm._v(" 条 "),
                          ]),
                          _c("pagination", {
                            attrs: {
                              total: _vm.queryParams.total,
                              page: _vm.queryParams.pageNum,
                              limit: _vm.queryParams.pageSize,
                            },
                            on: {
                              "update:page": function ($event) {
                                return _vm.$set(
                                  _vm.queryParams,
                                  "pageNum",
                                  $event
                                )
                              },
                              "update:limit": function ($event) {
                                return _vm.$set(
                                  _vm.queryParams,
                                  "pageSize",
                                  $event
                                )
                              },
                              pagination: _vm.getList,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            title: "新增/编辑商城分类编号",
            visible: _vm.addSerialNumber,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addSerialNumber = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.diaForm,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上级类别", prop: "parentId" } },
                [
                  _c("Treeselect", {
                    attrs: {
                      options: _vm.categoryTreeOptions,
                      "show-count": true,
                      placeholder: "请选择上级类别",
                      normalizer: _vm.normalizer,
                    },
                    model: {
                      value: _vm.diaForm.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.diaForm, "parentId", $$v)
                      },
                      expression: "diaForm.parentId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类别编码", prop: "categoryNo" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入类别编码", maxlength: "11" },
                    model: {
                      value: _vm.diaForm.categoryNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.diaForm, "categoryNo", _vm._n($$v))
                      },
                      expression: "diaForm.categoryNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类别名称", prop: "categoryName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入类别名称", maxlength: "30" },
                    model: {
                      value: _vm.diaForm.categoryName,
                      callback: function ($$v) {
                        _vm.$set(_vm.diaForm, "categoryName", $$v)
                      },
                      expression: "diaForm.categoryName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "备注长度介于 1 和 30 字符之间",
                      maxlength: "30",
                    },
                    model: {
                      value: _vm.diaForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.diaForm, "remark", $$v)
                      },
                      expression: "diaForm.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类图标" } },
                [
                  _c("OssUpload", {
                    attrs: {
                      option: {
                        listType: "img",
                        type: ["jpg", "png"],
                        size: "2 mb",
                        tip: "建议图标尺寸100*100px、背景做透明处理，",
                        autoUpload: true,
                      },
                    },
                    model: {
                      value: _vm.diaForm.imageUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.diaForm, "imageUrl", $$v)
                      },
                      expression: "diaForm.imageUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类海报" } },
                [
                  _c("OssUpload", {
                    attrs: {
                      option: {
                        listType: "img",
                        type: ["jpg", "png"],
                        size: "2 mb",
                        autoUpload: true,
                      },
                    },
                    model: {
                      value: _vm.diaForm.posterImageUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.diaForm, "posterImageUrl", $$v)
                      },
                      expression: "diaForm.posterImageUrl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addDialogOk } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.handleClose } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogImageVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogImageVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }