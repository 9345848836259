var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "门店价格策略",
        visible: _vm.show,
        width: "50%",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%", "max-height": "540px" },
              attrs: {
                data: _vm.shoplist,
                border: "",
                height: "60vh",
                "row-key": "shopId",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", { attrs: { type: "index" } }),
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "90",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "shopName",
                  align: "center",
                  label: "门店",
                  width: "180",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._l(_vm.tableHander, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    align: "center",
                    label: item.label,
                    prop: item.prop,
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row[item.prop],
                                callback: function ($$v) {
                                  _vm.$set(scope.row, item.prop, $$v)
                                },
                                expression: "scope.row[item.prop]",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "是否使用实时库存",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            disabled: _vm.updateDisabled,
                            "active-value": 1,
                            "inactive-value": 0,
                          },
                          model: {
                            value: scope.row.stockUseMode,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "stockUseMode", $$v)
                            },
                            expression: "scope.row.stockUseMode",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "虚拟库存",
                  prop: "stockQuantity",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            controls: false,
                            precision: 0,
                            step: 1,
                            min: 0,
                            disabled: scope.row.stockUseMode == 1,
                            label: "请输入虚拟库存",
                            size: "mini",
                          },
                          model: {
                            value: scope.row.stockQuantity,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "stockQuantity", $$v)
                            },
                            expression: "scope.row.stockQuantity",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }