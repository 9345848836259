<template>
  <div class="container">
    <div class="b bg-Color tableBox padd15">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="`已上架商品(${badge.onlineQty || 0})`"
          name="OnTheShelf"
        ></el-tab-pane>
        <el-tab-pane
          :label="`未上架商品(${badge.offLineQty || 0})`"
          name="OffTheShelf"
        ></el-tab-pane>
        <el-tab-pane
          :label="`商城商品(${badge.unSyncQty || 0})`"
          name="all"
        ></el-tab-pane>
      </el-tabs>
      <div class="marB15 x-bc">
        <div class="x-f">
          <el-button
            v-if="activeName == 'OffTheShelf'"
            type="primary"
            size="mini"
            icon="el-icon-sort-up"
            @click="putawayBtn(0)"
            style="background: #00c21a; border-color: #00c21a; color: #fff"
            :disabled="selectData.length < 1"
            >上架
          </el-button>
          <el-button
            v-if="activeName == 'OnTheShelf'"
            type="primary"
            size="mini"
            icon="el-icon-sort-down"
            @click="putawayBtn(1)"
            style="background: #fc3c22; border-color: #fc3c22; color: #fff"
            :disabled="selectData.length < 1"
            >下架
          </el-button>
          <el-button
            v-if="activeName != 'all'"
            type="primary"
            size="mini"
            icon="el-icon-delete"
            :disabled="selectData.length < 1"
            @click="updateAll()"
            >批量修改
          </el-button>
          <el-input
            v-model="treeQueryParams.queryCode"
            size="mini"
            v-if="activeName != 'all'"
            placeholder="商城商品编号、商城商品名称、商城商品品类"
            class="inputWidth marR10 marL10"
          ></el-input>
          <el-button
            v-if="activeName != 'all'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchOkBtn"
            >搜索
          </el-button>
        </div>
      </div>
      <div class="x-start Box">
        <div class="treeBox" v-if="activeName == 'all'">
          <div class="x-f marB15" style="width: 100%">
            <el-input
              style="width: 95%"
              v-model="treeQueryParams.queryCode"
              placeholder="请输入类别名称"
            ></el-input>
          </div>
          <el-tree
            ref="tree"
            :data="categoryTreeOptions"
            node-key="id"
            highlight-current
            :filter-node-method="filterNode"
            :default-expand-all="isExpand"
            @node-click="handleNodeClick"
          >
          </el-tree>
        </div>
        <div
          class="y-start"
          :class="{
            marL15: activeName == 'all',
            'width100-15': activeName == 'all',
            width100: activeName != 'all',
          }"
        >
          <div class="x-bc marB10" style="width: 100%" v-if="activeName == 'all'">
            <div class="x-f">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-folder-add"
                @click="save()"
                :disabled="selectData.length < 1"
                >保存
              </el-button>
              <el-input
                v-model="queryCode"
                size="mini"
                placeholder="商城商品编号、商城商品名称、商城商品品类"
                class="inputWidth marL10"
              ></el-input>
              <el-button
                type="primary"
                size="mini"
                class="marL10"
                icon="el-icon-search"
                @click="searchOkBtn"
                >搜索
              </el-button>
            </div>
          </div>
          <div class="width100">
            <el-table
              ref="goodsTable"
              :data="goodsList"
              v-loading="loading"
              border
              :height="500"
              @selection-change="selectDataFn"
            >
              <el-table-column type="selection" align="center" width="55" />
              <el-table-column type="index" align="center" label="序号" />
              <el-table-column prop="goodsNo" align="center" label="商品编码" width="220">
                <template slot-scope="scope">
                  <el-button
                    v-if="activeName != 'all'"
                    type="text"
                    @click.stop="editGood(scope.row.goodsId)"
                  >
                    {{ scope.row.goodsNo }}
                  </el-button>
                  <span v-else> {{ scope.row.goodsNo }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="图片" width="100">
                <template slot-scope="scope">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="scope.row.goodsUrl"
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="goodsName" align="center" label="商品名称" />
              <el-table-column prop="categoryName" align="center" label="商城分类" />
              <!-- <el-table-column prop="address" align="center" label="商品类别" /> -->
              <!-- <el-table-column prop="address" align="center" label="商品标签" /> -->
              <el-table-column prop="goodsSpec" align="center" label="商品规格" />
              <el-table-column prop="unitName" align="center" label="商品单位" />
              <!-- <el-table-column prop="weight" align="center" label="商品重量" /> -->
              <el-table-column
                prop="status"
                align="center"
                label="上架状态"
                :formatter="(row) => (Number(row.status) === 1 ? '未上架' : '已上架')"
              />
              <!-- <el-table-column prop="goodsType" align="center" width="180" label="商品类型">
                                <template slot-scope="scope">
                                    成品
                                </template>
                            </el-table-column> -->
            </el-table>
            <div class="pagination-footer x-bc" style="width: 100%">
              <div class="x-f">
                <div>
                  已选
                  <span style="color: #1c75f4">{{ selectDataLength }}</span>
                  条
                </div>
                <div class="marL10 cursorP">
                  <span style="color: #1c75f4" @click="selectAll">{{
                    isSelectAll ? "取消选择" : "选择全部"
                  }}</span>
                </div>
              </div>
              <pagination
                :total="total"
                :pageSizeList="[15, 30, 50, 100]"
                :page.sync="pageNum"
                :limit.sync="pageSize"
                @pagination="nextPage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <BatchEditGoods :show.sync="showBarchDialog" :selectData="selectData" @close="closeBatchEditGoods"></BatchEditGoods> -->
    <el-dialog
      v-loading="formLoading"
      :title="isChangeAll ? '批量修改上架商品信息' : '选择商城分类'"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="80px"
        class="demo-ruleForm"
        v-if="isChangeAll"
      >
        <el-form-item label="商城分类" prop="categoryId">
          <Treeselect
            style="z-index: 666"
            v-model="form.categoryId"
            :options="mallCategoryTreeOptions2"
            :show-count="true"
            placeholder="请选择分类"
            :normalizer="normalizer1"
            :open="getMallCategory"
            @select="select"
          />
        </el-form-item>
      </el-form>
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="80px"
        class="demo-ruleForm"
        v-else
      >
        <el-form-item label="商城分类" prop="categoryId">
          <Treeselect
            style="z-index: 666"
            v-model="form.categoryId"
            :options="mallCategoryTreeOptions"
            :show-count="true"
            placeholder="请选择分类"
            :normalizer="normalizer1"
            :open="getMallCategory"
            @select="select"
          />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save(false)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CButton from "@/views/components/c-button/index.vue";
import { listCategoryTree } from "@/api/goods/category";
import { listGoods, listGoodsInfoList } from "@/api/goods/goods";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import {
  goodsList,
  copyBasisGoodsToMall,
  batchLaunch,
  categoryLiStTree,
  batchUpdateGoods,
  goodsBadge,
} from "@/api/O2OMall/goods/list";

export default {
  name: "index",
  dicts: ["goods_type"],
  components: {
    CButton,
    Treeselect,
  },
  data() {
    return {
      isChangeAll: false,
      formLoading: false,
      form: {
        categoryId: undefined,
      },
      rules: {
        categoryId: [{ required: true, message: "请选择分类", trigger: "change" }],
      },
      dialogVisible: false,
      mallCategoryTreeOptions: [],
      mallCategoryTreeOptions2: [],
      loading: true,
      activeName: "OnTheShelf",
      btnIcon: "el-icon-arrow-down", // 高级搜索按钮箭头
      // showSearch: false, // 显示高级搜索
      // OnTheShelfNum: 0, // 上架数量 （请求来的数据+未上架页添加上架的）
      // OffTheShelfNum: 0, // 下架数量 (选中树，在选中列表的数据默认为未上架）
      queryCode: "",
      tabIndex: 0, // 列表选项卡点击下标
      goodsList: [], // 列表数据
      goodsAllList: undefined, // 全选数据
      // table
      total: 0,
      selectDataLength: 0, // 列表选的的条数
      isSelectAll: false, // 是否选择全部
      queryParams: {
        pageSize: 15,
        pageNum: 1,
      },
      selectData: [], // 列表多选的数据
      // tree
      categoryTreeOptions: [], // 商品分类树
      // 查询参数
      // treeTotal: 0,
      treeQueryParams: {
        goodsName: "",
        goodsNo: "",
        categoryId: "",
        status: 0,
        goodsType: 1,
        queryCode: "",
      },
      pageNum: 1,
      pageSize: 15,
      TreeId: undefined, // 点击树节点的ID
      isExpand: true, // 是否展开树

      badge: {}, // 三个列表分别的总数量（角标）
    };
  },
  created() {
    this.getTreeselect();
    this.getMallCategory();
    this.getShopGoodsList();
    this.getGoodsBadge();
  },
  methods: {
    //treeSelect结构属性转换
    normalizer1(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    select(e) {
      if (e.disableBranchNodes) {
        return false;
      }
    },
    /** 查询商品列表 */
    getList() {
      this.goodsList = [];
      if (this.activeName == "all") {
        this.treeQueryParams.status = 0;
      }
      this.loading = true;
      listGoodsInfoList({
        ...this.treeQueryParams,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      })
        .then((res) => {
          console.log("商品列表：", res);

          if (this.isSelectAll) {
            this.selectData = res.total;
          } else {
            this.goodsList = res.rows;
            this.total = res.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 商品分类树点击事件
    handleNodeClick(data) {
      this.TreeId = data.id;
      this.treeQueryParams.categoryId = data.id;
      this.treeQueryParams.pageNum = 1;
      this.getList();
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    /** 查询商品类别下拉树结构 */
    getMallCategory() {
      categoryLiStTree({ isCategoryOutThree: true }).then((response) => {
        this.mallCategoryTreeOptions = response.data;
        if (response.data?.length === 1 && response.data?.[0]?.children?.length) {
          this.mallCategoryTreeOptions2 = response.data[0].children;
        } else {
          this.mallCategoryTreeOptions2 = [];
        }
      });
    },
    getTreeselect() {
      listCategoryTree().then((response) => {
        if (response.data?.length === 1 && response.data?.[0]?.children?.length) {
          this.categoryTreeOptions = response.data[0].children;
        } else {
          this.categoryTreeOptions = [];
        }
      });
    },
    // 获取商城商品  未上架/已上架
    async getShopGoodsList() {
      this.goodsList = [];
      this.loading = true;
      try {
        let res = await goodsList({
          ...this.treeQueryParams,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        });
        this.goodsList = res.rows;
        this.total = res.total;
        this.getGoodsBadge();
      } catch (error) {}
      this.loading = false;
    },
    // 标签页点击获取下标
    handleClick(tab) {
      this.treeQueryParams.status = tab.index;
      this.treeQueryParams.pageNum = 1;
      if (tab.index == 2) {
        this.getList();
      } else {
        this.getShopGoodsList();
      }
    },
    // 确定搜索
    searchOkBtn() {
      if (this.activeName == "all") {
        this.getList();
      } else {
        this.getShopGoodsList();
      }
    },
    // 接收列表选择的数据
    selectDataFn(data) {
      this.selectData = data;
      this.selectDataLength = data.length;
    },
    // 上架商品按钮
    putawayBtn(status) {
      batchLaunch({
        goodsIds: this.getGoodsId(),
        status: status,
      }).then((res) => {
        if (status == 0) {
          this.$modal.msgSuccess("上架成功，请到已上架商品查看。");
        } else {
          this.$modal.msgSuccess("下架成功，请到已上架商品查看。");
        }
        this.isSelectAll = false;
        this.getShopGoodsList();
        this.getGoodsBadge();
      });
    },
    // 获取角标接口
    getGoodsBadge() {
      goodsBadge().then((res) => {
        this.badge = res.data;
      });
    },
    updateAll() {
      this.isChangeAll = true;
      this.save(true);
    },
    // 全部商品选择后保存
    async save(e = true) {
      if (!e) {
        try {
          await this.$refs.form.validate();
        } catch (error) {
          return this.$message.error(Object.values(error)[0][0].message);
        }
        if (this.isChangeAll) {
          let arr = this.getGoodsId();
          if (arr.length > 0) {
            this.formLoading = true;
            batchUpdateGoods({
              goodsIds: arr,
              categoryId: this.form.categoryId,
            })
              .then((res) => {
                this.dialogVisible = e;
                this.$modal.msgSuccess("批量修改成功");
                this.isSelectAll = false;
                this.getShopGoodsList();
                this.getGoodsBadge();
              })
              .finally(() => {
                this.isChangeAll = false;
                this.formLoading = false;
              });
          }
        } else {
          let arr = this.getGoodsId();
          if (arr.length > 0) {
            this.formLoading = true;
            copyBasisGoodsToMall({
              goodsIds: arr,
              categoryId: this.form.categoryId,
            })
              .then((res) => {
                this.dialogVisible = e;
                this.$modal.msgSuccess("保存成功，请到未上架商品查看。");
                this.isSelectAll = false;
                this.getList();
                this.getGoodsBadge();
              })
              .finally(() => {
                this.isChangeAll = false;
                this.formLoading = false;
              });
          }
        }
      } else {
        this.form.categoryId = undefined;
        await this.$nextTick();
        try {
          this.$refs.form.clearValidate();
        } catch (error) {}
        await this.$nextTick();
        this.dialogVisible = e;
      }
    },
    // 编辑列表项
    editGood(goodsId) {
      this.$router.push({
        path: "/O2OMall/goods/list/editGoods/index",
        query: {
          goodId: goodsId,
        },
      });
    },
    // 生成被勾选的商品iD数组
    getGoodsId() {
      let goodsIds = [];
      this.selectData.forEach((item) => {
        goodsIds.push(item.goodsId);
      });
      return goodsIds;
    },
    // 分页器，切换页
    nextPage() {
      if (this.activeName == "all") {
        this.getList();
      } else {
        this.getShopGoodsList();
      }
    },
    // 选择全部
    async selectAll() {
      this.isSelectAll = !this.isSelectAll;
      let res;
      if (this.isSelectAll) {
        if (this.activeName == "all") {
          res = await listGoodsInfoList({
            ...this.treeQueryParams,
            pageNum: 1,
            pageSize: this.total,
          });
        } else {
          res = await goodsList({
            ...this.treeQueryParams,
            pageNum: 1,
            pageSize: this.total,
          });
        }
        this.goodsList = res.rows;
        this.selectData = res.rows;
        this.selectDataLength = res.total;
        this.total = res.total;
        this.goodsList.forEach((row) => {
          this.$nextTick(() => {
            this.$refs.goodsTable.toggleRowSelection(row, this.isSelectAll); //全选
          });
        });
      } else {
        this.selectData = [];
        this.selectDataLength = 0;
        this.$nextTick(() => {
          this.$refs.goodsTable.clearSelection();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  background: #e9e9e9;

  .btnList {
    padding: 10px;
  }
}

//.theBody {
//    margin: 20px;
//    .el-card {
//        min-height: 87vh;
//        padding: 10px;
//    }

.inputWidth {
  width: 360px;
}

.Box {
  width: 100%;

  .treeBox {
    border-right: 1px solid #cccccc;
    width: 260px;
    min-width: 260px;

    .treeIcon {
      width: 35px;
      height: 35px;
      font-size: 26px;
      cursor: pointer;
    }
  }

  .tableBox {
    width: calc(100% - 275px);
  }
}

.width100 {
  width: 100%;
}

.width100-15 {
  width: calc(100% - 275px);
}

//}
</style>
