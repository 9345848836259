<template>
    <div>
        <div v-for="(item, index) in table" :key="index">
            <el-table :data="item.tableData" style="width: 100%" border>
                <el-table-column type="selection" align="center" width="55"></el-table-column>
                <el-table-column label="操作" align="center" width="120">
                    <template slot-scope="scope">
                        <i disabled
                            @click="deleteBtn('table', index)"
                            class="el-icon-remove operateDel"
                        ></i>
                    </template>
                </el-table-column>
                <el-table-column prop="date" align="center" label="规格名称" width="180">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.name"
                                  @keyup.enter.native="keyup('goodsNo', $event, scope.$index)"
                                  @blur="watchScroll">
                            <i slot="suffix" class="el-icon-more" @click="selectGoods"></i>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="name" align="center" label="规格条码" width="180">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.name"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="address" align="center" label="单位">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.name"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="address" align="center" label="价格">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.name">
                            <template slot="prepend">￥</template>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="address" align="center" label="划线价">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.name">
                            <template slot="prepend">￥</template>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="库存">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.name">
                            <template slot="prepend">￥</template>
                        </el-input>
                    </template>
                </el-table-column>
            </el-table>
            <div class="specificationsBox marT10">
                <el-collapse v-model="item.activeNames" @change="handleChange">
                    <el-collapse-item title="规格属性" name="1">
                        <div>
                            <el-form-item label="商品属性">
                               <div v-for="(att, inx) in item.attribute" class="marB10">
                                   <div class="x-start">
                                       <el-select v-model="msg.value" clearable placeholder="请选择商品品牌"
                                                  class="inputW200">
                                           <el-option v-for="item in 6" :key="item.brandId"
                                                      :label="item.brandName" :value="item.brandId"
                                           />
                                       </el-select>
                                       <el-button class="marL10" type="text" @click="deleteBtn('att',index, inx)">删除</el-button>
                                   </div>
                                   <div class="marT10 x-start">
                                       <el-tag class="marR10" closable @close="closeTag(index,inx,tagI)" v-for="(tag, tagI) in att.tags" :key="tagI">
                                           {{tag.title +' ￥'+ tag.price}}</el-tag>
                                       <el-button class="" size="mini" icon="el-icon-plus" @click="addTag(index,inx)"></el-button>
                                   </div>
                               </div>
                                <div class="marT10">
                                    <el-button class="" size="mini" type="primary" plain icon="el-icon-plus" @click="addproperty(index)">添加属性</el-button>
                                </div>
                            </el-form-item>


                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </div>
</template>

<script>
import { listGoods } from '@/api/goods/goods'

export default {
    name: 'specifications',
    props: {
        table: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            activeNames: ['1'],
            msg:{
                value: ''
            },
        }
    },
    methods: {
        // 关闭tag触发
        closeTag(index,inx,tagI){
            this.$emit('delProperty', {
                str: 'tag',
                pInx: index,
                inx: inx,
                tagI: tagI
            })
        },
        handleChange(val) {
            console.log(val)
        },
        //监听数据变化失去焦点时  滚动条的位置
        watchScroll() {
            if (this.scrollLeft) this.savescroll()
        },
        //选择商品方法
        selectGoods() {
            this.OpenGoods = true
        },
        //表格单元格回车事件
        keyup(name, event, index) {
            //判断是否输入编码或条码
            if (this.form.billDetailItems[`${index}`][`${name}`]) {
                listGoods({
                    [name]: this.form.billDetailItems[`${index}`][`${name}`]
                }).then(response => {
                    // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
                    if (response.total === 1) {
                        event.target.blur()
                        //原始赋值
                        if (response.rows[0].remark) {
                            response.rows[0].remark = ''
                            response.rows[0].unitQty = 0
                            response.rows[0].unitPrice = response.rows[0].salePrice
                            response.rows[0].unitMoney = 0
                        }
                        //使用vue的$set方法更新数组数据,使视图可编辑
                        this.$set(this.form.billDetailItems, index, response.rows[0])
                    } else {
                        this.queryCode = this.form.billDetailItems[`${index}`][`${name}`]
                        console.log('this.queryCode:', this.queryCode)
                        this.OpenGoods = true
                    }
                })
            } else {
                //没输入直接弹出商品信息框
                event.target.blur()
                this.OpenGoods = true
            }
        },
        // 添加属性
        addproperty(index){
            this.$emit('addAttribute', index)
        },
        // 添加tag
        addTag(inx, index){
            console.log('添加标签：', inx, index)
            this.$emit('addTag', {pinx: inx, inx: index})
        },
        deleteBtn(str,index, inx){
            this.$emit('delProperty', {str: str, pInx: index, inx: inx})
        },

    }
}
</script>

<style scoped>
.specificationsBox{
    /*border: 1px solid #cccccc;*/
    width: 900px;
    padding: 10px;

}
</style>
